import { Box, Tab, Tabs, tabsClasses } from '@mui/material';
import { isArray } from 'lodash';
import { useEffect, useState } from 'react';
import { QueryMapping } from 'src/hooks/use-breakpoint';
import { useMyPathname } from 'src/hooks/use-locale';
import { MyLink } from '../mine/my-link';

type NavItem = {
  title: JSX.Element;
  path: string | string[];
};
type NavigationCarouselProps = {
  items: NavItem[];
};

const selectedStyle = {
  color: 'primary.main',
  filter:
    'brightness(0) saturate(100%) invert(49%) sepia(77%) saturate(1872%) hue-rotate(197deg) brightness(101%) contrast(101%)',
};

export const NavigationCarousel: React.FC<NavigationCarouselProps> = ({
  items,
}) => {
  const [tab, setTab] = useState(0);

  const { pathname } = useMyPathname();

  useEffect(() => {
    const index = items.findIndex((item) =>
      isArray(item.path)
        ? item.path.includes(pathname)
        : item.path === pathname,
    );
    if (index === -1) return;
    setTab(index);
  }, [items, pathname]);

  return (
    <Box
      sx={{
        display: 'none',
        [QueryMapping.mobile]: {
          display: 'block',
        },
      }}>
      <Tabs
        variant="scrollable"
        scrollButtons={false}
        value={tab}
        onChange={(e, value) => {
          setTab(value);
        }}
        sx={{
          [`& .${tabsClasses.flexContainer}`]: {
            alignItems: 'baseline',
          },
        }}
        TabIndicatorProps={{
          style: {
            display: 'none',
          },
        }}>
        {items.map((item, index) => (
          <Tab
            key={index}
            label={<NavigationItem item={item} />}
            sx={{
              width: 'auto',
              minWidth: '60px',
              maxWidth: '60px',
              marginRight: 3,
              padding: 0,
              '& a': {
                ...(index === tab ? selectedStyle : { color: 'common.white' }),
              },
            }}
          />
        ))}
      </Tabs>
    </Box>
  );
};

const NavigationItem: React.FC<{ item: NavItem }> = ({ item }) => {
  const path = isArray(item.path) ? item.path[0] : item.path;

  return (
    <MyLink
      href={path}
      sx={{
        textDecoration: 'none !important',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: 'common.white',
      }}>
      {item.title}
    </MyLink>
  );
};
